import { routes } from '@routes';

import { useAuthContext } from '@providers/AuthProvider';
import { TOrganization } from 'app/types/entities/TOrganization';
import { formatCentsPrice } from '@helpers/formatCentsPrice';
import { formatPrice } from '@helpers/formatPrice';

import { Header } from '@components/Header';
import { CustomTable } from '@components/Table';
import { LinearProgressLoader } from '@components/spinners/LinearProgressLoader';
import { PagePagination } from '@components/Pagination';

import { usePageContext } from '../../providers/PageProvider';
import { InlineEditRow } from './components/InlineEditRow';

import { Wrapper } from './styles/Wrapper';
import { StyledLink } from './styles/StyledLink';
import { PlusBalanceIcon } from './styles/PlusBalanceIcon';
import { PencilIcon } from './styles/PencilIcon';
import { CellContent } from './styles/CellContent';
import { StyledIconButton } from '@pages/Admin/AdminsListPage/components/Content/components/Table/styles/StyledIconButton';
import { Edit as EditIcon } from '@components/icons/Edit';
import { Done as DoneIcon } from '@components/icons/Done/svg';

export const Content = () => {
  const { me } = useAuthContext();
  const isVsBilling = me?.roles?.ROLE_BILLING_ADMIN;
  const {
    organizations,
    isLoading,
    search,
    setSearch,
    pageSize,
    setPageSize,
    page,
    setPage,
    exportCSV,
    openBalanceModal,
    openRateModal,
    editId,
    setEditId,
  } = usePageContext();

  const tableHead = [
    {
      headComponent: () => <td>#</td>,
      render: (data: TOrganization, index: number) => <CellContent>{index + 1}</CellContent>,
    },
    {
      headComponent: () => <td>Name</td>,
      render: (data: TOrganization) => (
        <StyledLink to={routes.adminOrganizationDetails.make(data.id)}>{data.name}</StyledLink>
      ),
    },
    {
      headComponent: () => <td>URL</td>,
      render: (data: TOrganization) => <CellContent>{data.slug}</CellContent>,
    },
    {
      headComponent: () => <td>Members</td>,
      render: (data: TOrganization) => (
        <StyledLink to={routes.adminOrganizationUsersList.make(data.id)}>
          {data.membersCount} {data.membersCount === 1 ? 'member' : 'members'}
        </StyledLink>
      ),
    },
    {
      headComponent: () => <td>Workspaces</td>,
      render: (data: TOrganization) => (
        <CellContent>
          {data.workspacesCount} {data.workspacesCount === 1 ? 'workspace' : 'workspaces'}
        </CellContent>
      ),
    },
    {
      headComponent: () => <td>Balance</td>,
      render: (data: TOrganization) => (
        <CellContent>
          {formatCentsPrice(data.currentBalance ?? 0)}
          {isVsBilling && data.billingAccountId ? (
            <PlusBalanceIcon
              onClick={(e: React.MouseEvent<SVGElement>) => openBalanceModal(e, data.billingAccountId ?? '')}
            />
          ) : null}
        </CellContent>
      ),
    },
    {
      headComponent: () => <td>Rate</td>,
      render: (data: TOrganization) => (
        <CellContent>
          {data.BillingAccount ? (
            <>
              {data.BillingAccount.fixedRate ? 'Fixed: ' : ''}
              {formatPrice(data.BillingAccount.postProcessingASRRate ?? 0)}/hr
              {isVsBilling ? (
                <PencilIcon
                  onClick={(e: React.MouseEvent<SVGElement>) => openRateModal(e, data.BillingAccount?.id ?? '')}
                />
              ) : null}
            </>
          ) : null}
        </CellContent>
      ),
    },
    {
      headComponent: () => <td>Actions</td>,
      render: (data: TOrganization) => (
        <CellContent>
          {data.id !== editId ? (
            <StyledIconButton title="Edit" onClick={() => setEditId(data.id)}>
              <EditIcon />
            </StyledIconButton>
          ) : null}
          {data.id === editId ? (
            <StyledIconButton title="Done" onClick={() => setEditId('')}>
              <DoneIcon width={18} height={14} />
            </StyledIconButton>
          ) : null}
        </CellContent>
      ),
    },
  ];

  const renderInlineEditRow = (data: TOrganization) => {
    return <InlineEditRow data={data} />;
  };

  return (
    <>
      <Header
        title={'Organizations'}
        buttonLabel={organizations.data?.length ? 'Export CSV' : undefined}
        buttonOnClick={organizations.data?.length ? () => exportCSV() : undefined}
        setSearch={setSearch}
        search={search}
      />

      <LinearProgressLoader active={isLoading} />
      <Wrapper>
        <CustomTable head={tableHead} data={organizations.data} renderInlineEditRow={renderInlineEditRow} />
        <PagePagination
          totalItemsCount={organizations.count ?? 0}
          page={page ?? 1}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      </Wrapper>
    </>
  );
};
