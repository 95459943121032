import { createContext, useContext, useEffect, useState } from 'react';

import { TPageProviderProps } from 'app/types/providers/TPageProviderProps';
import { TPaginatedResponse } from 'app/types/API/TPaginatedResponse';

import { AdminService, FeatureFlagsService } from 'app/API';
import { useModalContext } from '@providers/ModalProvider';
import { useAPI } from '@hooks/useAPI';
import { formatCentsPrice } from '@helpers/formatCentsPrice';
import { getAmericanTime } from '@helpers/getAmericanTimezone';
import { downloadCSV } from '@helpers/downloadCSV';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';
import { TOrganization } from 'app/types/entities/TOrganization';
import { formatPrice } from '@helpers/formatPrice';

type TState = {
  editId: string;
  setEditId: (v: string) => void;
  editOrganizationFeatureFlag: (id: string, name: string, value: boolean) => void;
  organizations: TPaginatedResponse<TOrganization>;
  isLoading: boolean;
  setIsLoading: (v: boolean) => void;
  sortingField: string;
  setSortingField: (v: string) => void;
  sortingReverse: boolean;
  setSortingReverse: (v: boolean) => void;
  search: string;
  setSearch: (v: string) => void;
  pageSize: number;
  setPageSize: (v: number) => void;
  page: number;
  setPage: (v: number) => void;
  reloadPage: () => void;
  exportCSV: () => void;
  openBalanceModal: (e: React.MouseEvent<SVGElement>, billingAccountId: string) => void;
  openRateModal: (e: React.MouseEvent<SVGElement>, billingAccountId: string) => void;
};

const PageContext = createContext<TState>({
  editId: '',
  setEditId: () => null,
  editOrganizationFeatureFlag: () => null,
  organizations: {
    data: [],
    count: 0,
  },
  isLoading: false,
  setIsLoading: () => null,
  sortingField: 'createdAt',
  setSortingField: () => null,
  sortingReverse: true,
  setSortingReverse: () => null,
  search: '',
  setSearch: () => null,
  pageSize: 25,
  setPageSize: () => null,
  page: 1,
  setPage: () => null,
  reloadPage: () => null,
  exportCSV: () => null,
  openBalanceModal: () => null,
  openRateModal: () => null,
});

export const PageProvider = ({ children }: TPageProviderProps) => {
  const { call } = useAPI();
  const { openModal, closeModal } = useModalContext();
  const [editId, setEditId] = useState('');
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [sortingField, setSortingField] = useState('createdAt');
  const [sortingReverse, setSortingReverse] = useState(true);
  const [pageSize, setPageSize] = useState(25);

  const [organizations, setOrganizations] = useState<TPaginatedResponse<TOrganization>>({
    data: [],
    count: 0,
  });
  const [isLoading, setIsLoading] = useState(false);

  const openBalanceModal = (e: React.MouseEvent<SVGElement>, billingAccountId: string) => {
    e.stopPropagation();
    openModal(ModalNamesEnum.AddAdminTransactionModal, {
      billingAccountId,
      onSuccess: () => {
        reloadPage();
      },
    });
  };

  const openRateModal = (e: React.MouseEvent<SVGElement>, billingAccountId: string) => {
    e.stopPropagation();
    openModal(ModalNamesEnum.AdminBillingAccountRate, {
      billingAccountId,
      onSuccess: () => {
        reloadPage();
      },
    });
  };

  const exportCSV = async () => {
    const items = await call(
      AdminService.getOrganizationsList({
        pageNo: '1',
        pageSize: organizations.count.toString(),
        orderBy: 'createdAt',
        orderType: 'desc',
        search,
      }),
    );
    const headers = ['ID', 'Name', 'URL', 'Members', 'Workspaces', 'Balance', 'Rate', 'Created'];
    const array = items.data.map((data: TOrganization) => {
      let rate = data.BillingAccount?.fixedRate ? 'Fixed: ' : '';
      if (data.BillingAccount) {
        rate += `${formatPrice(data.BillingAccount?.postProcessingASRRate ?? 0)}/hr}`;
      }
      return {
        id: data.id,
        name: data.name,
        url: data.slug,
        membersCount: data.membersCount ?? 0,
        workspacesCount: data.workspacesCount ?? 0,
        currentBalance: formatCentsPrice(data.currentBalance ?? 0),
        rate,
        created: getAmericanTime(data.createdAt),
      };
    });
    return downloadCSV(array, headers);
  };

  const editOrganizationFeatureFlag = async (oid: string, name: string, value: boolean) => {
    setIsLoading(true);
    await call(FeatureFlagsService.updateOrganizationFeatureFlag({ oid, requestBody: { name, value } }));
    reloadPage();
  };

  const reloadPage = async () => {
    closeModal();
    setIsLoading(true);
    const data = await call(
      AdminService.getOrganizationsList({
        pageNo: page.toString(),
        pageSize: pageSize.toString(),
        orderBy: sortingField,
        orderType: sortingReverse ? 'desc' : 'asc',
        search,
      }),
    );
    setOrganizations(data);
    setIsLoading(false);
  };

  useEffect(() => {
    reloadPage();
  }, [page, pageSize, search]);

  return (
    <PageContext.Provider
      value={{
        editId,
        setEditId,
        editOrganizationFeatureFlag,
        organizations,
        isLoading,
        setIsLoading,
        sortingField,
        setSortingField,
        sortingReverse,
        setSortingReverse,
        search,
        setSearch,
        pageSize,
        setPageSize,
        page,
        setPage,
        reloadPage,
        exportCSV,
        openBalanceModal,
        openRateModal,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export const usePageContext = () => useContext(PageContext);
