import styled from 'styled-components';

import { Header } from '@components/Header';
import { CustomTable } from '@components/Table';
import { LinearProgressLoader } from '@components/spinners/LinearProgressLoader';
import { TransactionType } from '@pages/User/BillingAccountDetailsPage/components/TransactionType';
import { Tabs } from '@pages/Admin/TransactionsPage/components/Tabs';
import { PagePagination } from '@components/Pagination';
import { usePageContext } from '@pages/Admin/TransactionCodesPage/providers/PageProvider';
import { TTransactionCode } from 'app/types/entities/TTransactionCode';

const CellContent = styled.div`
  margin: 0;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .text-858dbd {
    color: #858dbd;
  }
`;
const MainContent = styled.main`
  padding: 8px 30px 70px;
  min-height: calc(100vh - 120px);

  td {
    vertical-align: top;
  }
`;

export const Content = () => {
  const { transactionCodes, isLoading, search, setSearch, pageSize, setPageSize, page, setPage } = usePageContext();

  const tableHead = [
    {
      headComponent: () => <td>Code</td>,
      render: (data: TTransactionCode) => <CellContent>{data.code}</CellContent>,
    },
    {
      headComponent: () => <td>Name</td>,
      render: (data: TTransactionCode) => <CellContent>{data.name}</CellContent>,
    },
    {
      headComponent: () => <td>Description</td>,
      render: (data: TTransactionCode) => (
        <CellContent>
          <div style={{ whiteSpace: 'normal' }}>{data.description}</div>
        </CellContent>
      ),
    },
    {
      headComponent: () => <td>Type</td>,
      render: (data: TTransactionCode) => (
        <CellContent>
          <TransactionType type={data.status} />
        </CellContent>
      ),
    },
  ];

  return (
    <>
      <Header
        title="Transactions"
        setSearch={setSearch}
        search={search}
        leftSideContent={[<Tabs key="TransactionsTabs" currentTab="Transaction Codes" />]}
      />

      <LinearProgressLoader active={isLoading} />
      <MainContent>
        <CustomTable head={tableHead} data={transactionCodes.data} />
        <PagePagination
          totalItemsCount={transactionCodes.count ?? 0}
          page={page ?? 1}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      </MainContent>
    </>
  );
};
