import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { useAuthContext } from '@providers/AuthProvider';
import { Button } from '@components/form-elements/buttons/Button';
import { AbsoluteSpinner } from '@components/spinners/Spinner';
import { routes } from '@routes';
import { getPaymentMethodsJobSubmit } from '@queries/billing-account/getPaymentMethodsJobSubmit';

import { TProps } from './types/TProps';
import { Wrapper } from './styles/Wrapper';
import { StyledButton } from './styles/StyledButton';

import { PriceModalErrors } from './components/errors';
import { PriceModalNoPriceDescription } from './components/noPriceDescription';
import { PriceModalPriceDescription } from './components/priceDescription';
import { MINIMUM_ACCEPTED_AUDIO_DURATION } from '@components/Modal/PriceModal/constants';
import { BillingService, PaymentService, PayService } from 'app/API';
import { useAPI } from '@hooks/useAPI';

export function PriceModal({ isSubmitting, setPaymentIntent, paymentIntent, onCancel, onSuccess, jobId }: TProps) {
  const { call } = useAPI();
  const { updateMe, workspace, organization } = useAuthContext();

  const [price, setPrice] = useState<{ price: number; ASRRate: string; duration?: number }>({ price: 0, ASRRate: '0' });
  const [paymentMethodId, setPaymentMethodId] = useState<string>();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [balanceIsRefreshing, setBalanceIsRefreshing] = useState(false);

  const navigate = useNavigate();

  const [audioDuration, setAudioDuration] = useState(0);

  const balance = organization?.billingAccount?.currentBalance ?? 0;
  const notEnoughFunds = (price?.price ?? 0) > balance;
  const cannotViewBilling = !organization?.permissions?.viewBilling;
  const noBillingAccount = !organization?.billingAccount?.id;
  const isAutoTopUpEnabled = organization?.billingAccount?.AutoTopUpSettings?.enabled;

  useEffect(() => {
    setDataLoaded(false);
    getPaymentMethodsJobSubmit(organization?.billingAccount?.id || '').then((methods) => {
      setPaymentMethodId(methods?.find((item) => item.default)?.id);
      updateMe().then(async () => {
        const priceData = await call(BillingService.calculateJobPricing({ jobId }));

        setAudioDuration(priceData?.result?.duration || 0);

        if (priceData?.result) {
          setPrice(priceData?.result);
        }

        setDataLoaded(true);
      });
    });
  }, []);

  useEffect(() => {
    const listener = async (ev: MessageEvent) => {
      if (ev.data === '3DS-authentication-complete') {
        const paymentIntentData = await call(PaymentService.getPaymentIntent({ id: paymentIntent.id }));
        setPaymentIntent({ url: '', id: '' });
        if (paymentIntentData.status === 'succeeded') {
          const { url } = await call(PayService.pay({ id: jobId }));
          if (!url.includes('/jobs')) {
            window.location.href = url;
            return;
          }
          navigate(routes.jobsList.make(organization?.slug ?? '', workspace?.slug ?? ''));
        }
      }
    };

    window.addEventListener('message', listener, false);

    return () => {
      window.removeEventListener('message', listener);
    };
  }, [paymentIntent]);

  // const shouldShowPrice = organization?.permissions?.viewJobCost;

  const loading = isSubmitting || !dataLoaded;
  const error = noBillingAccount || (!paymentMethodId && notEnoughFunds) || (notEnoughFunds && !isAutoTopUpEnabled);

  return (
    <Wrapper>
      {loading && <AbsoluteSpinner overlay={true} />}
      <h2 data-testid="priceModalH2">Confirm</h2>
      <div data-testid="priceDescription">
        {cannotViewBilling ? (
          <PriceModalNoPriceDescription price={price} />
        ) : (
          <PriceModalPriceDescription
            price={price}
            setDataLoaded={setDataLoaded}
            balanceIsRefreshing={balanceIsRefreshing}
            setBalanceIsRefreshing={setBalanceIsRefreshing}
            audioDuration={audioDuration}
          />
        )}
      </div>

      <div className={`errorsWrapper ${error ? 'active' : ''}`}>
        <PriceModalErrors
          setBalanceIsRefreshing={setBalanceIsRefreshing}
          price={price}
          onCancel={onCancel}
          setPaymentIntent={setPaymentIntent}
          paymentIntent={paymentIntent}
          dataLoaded={dataLoaded}
          setDataLoaded={setDataLoaded}
          onSuccess={onSuccess}
          jobId={jobId}
          isSubmitting={isSubmitting}
          paymentMethodId={paymentMethodId}
        />
      </div>

      <div className="buttonWrapper">
        <Button onClick={onCancel} variant="outlined">
          Cancel
        </Button>

        <StyledButton
          data-testid="priceModalSubmitButton"
          variant="contained"
          disabled={loading || audioDuration < MINIMUM_ACCEPTED_AUDIO_DURATION || error}
          onClick={() => {
            setDataLoaded(false);
            onSuccess();
            setTimeout(updateMe, 1000);
          }}
        >
          Transcribe
        </StyledButton>
      </div>
    </Wrapper>
  );
}
