import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

import { routes } from '@routes';

import { formatCentsPrice } from '@helpers/formatCentsPrice';

import { Header } from '@components/Header';
import { CustomTable } from '@components/Table';
import { getAmericanTime } from '@helpers/getAmericanTimezone';
import { DiagonalArrow } from '@components/icons/DiagonalArrow';
import { TransactionType } from '@pages/User/BillingAccountDetailsPage/components/TransactionType';
import { Popover } from '@components/Popover';
import { Filter } from 'app/pages/Admin/TransactionsPage/components/Content/components/Filter';
import { LinearProgressLoader } from '@components/spinners/LinearProgressLoader';
import { TTransaction } from 'app/types/entities/TTransaction';
import { PagePagination } from '@components/Pagination';

import { Tabs } from '../Tabs';
import { usePageContext } from '@pages/Admin/TransactionsPage/providers/PageProvider';

const StyledLink = styled(RouterLink)`
  color: #00122d;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  text-decoration: underline;

  &:hover {
    color: #40608f;
  }
`;

const ReceiptUrl = styled.a`
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  text-decoration-line: none;
  border-bottom: 1px solid #858dbd;
  display: inline-flex;
  color: #858dbd;

  :hover {
    border-bottom: 1px solid transparent;
  }
`;

const CellRowContent = styled.div`
  display: flex;
  align-items: center;
`;
const CellContent = styled.div`
  margin: 0;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .text-858dbd {
    color: #858dbd;
  }
`;
const Cell = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px 0;

  .text-858dbd {
    color: #858dbd;
  }
`;
const OrgCell = styled.div`
  padding: 6px;
  background-color: #f1f5fb;
  color: #858dbd;
  font-weight: 500;
  font-size: 12px;
  line-height: 90%;
  border-radius: 5px;
  align-items: center;
  margin: 20px 0;
`;

const Wrapper = styled.main`
  padding: 8px 30px 70px;
  min-height: calc(100vh - 120px);

  td {
    vertical-align: top;
  }
`;

export const Content = () => {
  const {
    transactions,
    isLoading,
    filter,
    page,
    setPage,
    pageSize,
    setPageSize,
    search,
    setSearch,
    setFilter,
    anchorEl,
    setAnchorEl,
    onClose,
    reloadPage,
    exportCSV,
  } = usePageContext();

  const FilterPopover = () => {
    return (
      <Popover
        styles="margin-top: 40px; margin-left: -100px;"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
      >
        <Filter filter={filter} filterOnChange={(e) => setFilter(e)} handleSubmit={reloadPage} handleClose={onClose} />
      </Popover>
    );
  };

  const tableHead = [
    {
      headComponent: () => <td>#</td>,
      render: (data: TTransaction, index: number) => <CellContent>{index + 1}</CellContent>,
    },
    {
      headComponent: () => <td>Created</td>,
      render: (data: TTransaction) => <CellContent>{getAmericanTime(data.createdAt)}</CellContent>,
    },
    {
      headComponent: () => <td>Title</td>,
      render: (data: TTransaction) => (
        <Cell>
          <div className="my-1">
            {data.title} <br />
            <small className="text-858dbd text-xs">Charge ID: {data.stripeChargeId ?? data.id}</small>
            {data.userResponsibleEmail ? (
              <div>
                <small className="text-858dbd text-xs">
                  Actioned by: {data.userResponsibleName} &lt;{data.userResponsibleEmail}&gt;
                </small>
              </div>
            ) : null}
            {data.jobId ? (
              <div>
                <small className="text-858dbd text-xs">
                  Related job: <StyledLink to={routes.adminJobDetails.make(data.jobId)}>{data.jobId}</StyledLink>
                </small>
              </div>
            ) : null}
          </div>
        </Cell>
      ),
    },
    {
      headComponent: () => <td>Organization</td>,
      render: (data: TTransaction) => (
        <CellRowContent>
          <OrgCell>
            <StyledLink to={routes.adminOrganizationDetails.make(data.organization?.id)}>
              {data.organization?.name}
            </StyledLink>
          </OrgCell>
        </CellRowContent>
      ),
    },
    {
      headComponent: () => <td>Amount</td>,
      render: (data: TTransaction) => <CellContent>{formatCentsPrice(data.amount ?? 0)}</CellContent>,
    },
    {
      headComponent: () => <td>Type</td>,
      render: (data: TTransaction) => (
        <CellContent>
          <TransactionType type={data.code.status} />
        </CellContent>
      ),
    },
    {
      headComponent: () => <td style={{ width: '85px', maxWidth: '85px' }}>Receipt Url</td>,
      render: (data: TTransaction) => (
        <CellContent>
          {data.stripeReceiptUrl ? (
            <ReceiptUrl href={data.stripeReceiptUrl} target="_blank" rel="noreferrer">
              Receipt{' '}
              <div className="mt-2 ml-1">
                <DiagonalArrow />
              </div>
            </ReceiptUrl>
          ) : null}
        </CellContent>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>Transactions - AutoScript Admin</title>
      </Helmet>
      <Header
        title="Transactions"
        buttonLabel={transactions.data?.length ? 'Export CSV' : undefined}
        buttonOnClick={transactions.data?.length ? () => exportCSV() : undefined}
        setSearch={setSearch}
        search={search}
        onFilterClick={(e) => setAnchorEl(e.currentTarget)}
        filterCounter={filter.from || filter.to ? 1 : 0}
        leftSideContent={[<Tabs key="TransactionsTabs" currentTab="Transactions" />]}
      />

      <LinearProgressLoader active={isLoading} />
      <Wrapper>
        <CustomTable head={tableHead} data={transactions.data} />
        <PagePagination
          totalItemsCount={transactions.count ?? 0}
          page={page ?? 1}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      </Wrapper>
      <FilterPopover />
    </>
  );
};
